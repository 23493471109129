//css animations used on the portfolio site

//title animations
@keyframes drop-down {
    from {
        transform: translateY(-100px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-enter {
    from {
        transform: translateX(100vw);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-exit {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        transform: translateX(-100vw);
    }
}


@mixin enter-mixin($delay) {
    animation: slide-enter;
    animation-delay: $delay;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.anim-slow {
    @include enter-mixin(1.8s);
}

.anim-fast {
    @include enter-mixin(0s);
}

.anim-exit {
    animation: slide-exit;
    animation-delay: 0;
    animation-duration: 1s;
    animation-fill-mode: both;
}