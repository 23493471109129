@import "../../Partials/colors";
@import "../../Partials/fonts";
.back-button {
    background: none;
    font-family: $font-stack;
    color: $green;
    outline: none;
    border: none;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 0 auto;
    transition: transform 0.3s ease;
    margin-top: 20px;

    &__arrow {
        height: 13px;
        margin-right: 6px;
    }

    &:hover {
        transform: scale(1.2);
        cursor: pointer;
    }
}