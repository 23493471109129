
.footer {
    width: 100%;
    height: 120px;
    border-top: 4px solid black;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;

    &__icon-box{
        display: flex;
        flex-direction: row;
        width: 300px;
        justify-content: space-between;
        height: 50px;
    }

    &__icon {
        height: 40px;
        transition: all 1s ease;

        &:hover, &:active {
            height: 50px;
        }
    }

    &__copyright {
        margin-bottom: 20px;
    }
}