//styling for the portfolio site header that appears on every page

//import partials
@import "../../Partials/mediaqueries";
@import "../../Partials/animations";
@import "../../Partials/colors";
@import "../../Partials/fonts";
@import "../../Partials/darkmode";

.portfolio-header {
    box-sizing: border-box;
    background: white;
    height: 180px;
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    
    //this part describes the sliding down animation of the header on page load
    animation-name: drop-down;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    &__nameplate {
        //this includes the title and the avatar image
        width: 100%;
        height: 100px;
        border-bottom: 4px solid black;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-family: $font-stack ; //google fonts for the win!
        font-size: 8vw; //font size scales with device screen width
        margin-left: 20px;

        //this part does the gradient
        background-image: linear-gradient(90deg,$darkgreen 0%, $green 60%, $lightgreen 100%);
        background-size: 100%;
        background-clip: content-box;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;

        @include tablet {
            font-size: 60px;
            margin-left: 50px;
        }

        @include desktop {
            margin-left: 200px;
        }
    }

    &__imagebox {
        //the little circle that holds my avatar image
        height: 60px;
        width: 60px;
        border-radius: 30px;
        border: 4px solid $green;
        margin-right: 20px;
        box-sizing: border-box;

        //the avatar is a background image
        background-image: url("../../Assets/Jackson2021.png");
        background-size: contain;

        //fade in animation
        animation-name: fade-in;
        animation-delay: 0.6s;
        animation-duration: 1.6s;
        animation-fill-mode: both;

        @include tablet {
            //larger image for tablets
            height: 80px;
            width: 80px;
            border-radius: 40px;
            margin-right: 50px;
        }

        @include desktop {
            margin-right: 200px;
        }
    }
}

.portfolio-nav {
    //take up most of screen width on phones
    width: 90vw;

    @include tablet {
        //set width on tablets
        width: 500px;
    }

    &__bar {
        //orient the navbar
        display: flex;
        flex-direction: row;
        list-style: none; //no bullets
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 10px 20px;
    }

    &__li {
        //define the animation for a nav link
        animation: fade-in;
        animation-duration: 1s;
        animation-fill-mode: both;
        font-size: 1rem;
        width: 100px;
        height: 30px;

        @include tablet {
            font-size: 1.5rem;
            width: 130px;
            height: 30px;
        }

        //these determine animation timings for different nav links
        &--1 {
            animation-delay: 1s;
        }
        &--2 {
            animation-delay: 1.6s;
        }
        &--3 {
            animation-delay: 2.2s;
        }
    }

    &__link {
        text-decoration: none;
        border: none;
        background: none;
        outline: none;
        color: black;
        transition: all 0.5s ease;
        font-size: 1rem;
        font-family: $font-stack-text;


        &:hover, &:active, &--active {
            color: $green;
            font-size: 1.2rem;
            cursor: pointer;

            @include tablet {
                font-size: 1.6rem;
            }
        }

        
    }
}