//import partials
@import "../../../Partials/mediaqueries";
@import "../../../Partials/animations";
@import "../../../Partials/colors";
@import "../../../Partials/fonts";
@import "../../../Partials/darkmode";

.thermo-box {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.thermo-content {
    margin: 0 20px;

    @include tablet {
        margin: 0 50px;
    }

    @include desktop {
        width: 1000px;
    }
}

.thermo-title {
    font-family: $font-stack;
    font-size: 8vw;

    @include tablet {
        font-size: 60px;
    }
}

.thermo-img {
    height: 200px;
    width: 160px;
    border-radius: 16px;
    margin-bottom: 20px;

    @include tablet {
        height: 300px;
        width: 240px;
    }
}

//each content sections extends this one
.section {
    margin-top: 40px;
}
//each page section extends this title styling
.section-title {
    font-size: 30px;
    font-family: $font-stack;
    color: $green;
}

.thermo-desc {
    @extend .section;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    &__info {
        @include tablet {
            margin-top: 40px;
            margin-left: 40px;
        }
    }

    &__header {
        @extend .section-title;
        margin-bottom: 20px;
    }

    &__description {
        text-align: left;
    }

    &__stats {
        text-align: left;
    }
}

.thermo-video {
    @extend .section;

    &__header {
        @extend .section-title;
    }
}

.unstyled-link {
    color: black;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.4s ease;

    &:hover, &:active {
        color: $green;
    }
}