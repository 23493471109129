//import partials
@import "../../Partials/mediaqueries";
@import "../../Partials/animations";
@import "../../Partials/colors";
@import "../../Partials/fonts";
@import "../../Partials/darkmode";

.portfolio-box {
    display: flex;
    justify-content: center;

    
}

.portfolio-web {
    margin: 10px 20px;
    flex-grow: 1;

    @include tablet {
        margin: 10px 50px;
    }

    @include desktop {
        width: 1000px;
        flex-grow: 0;
    }

    &__title {
        font-family: $font-stack;
        font-size: 8vw;

        @include tablet {
            font-size: 60px;
        }
    }
}

//each content sections extends this one
.section {
    margin-top: 40px;
}

//each page section extends this title styling
.section-title {
    font-size: 30px;
    font-family: $font-stack;
    color: $green;
}

.web-projects {
    //this is the "What Do I Do?" section
    @extend .section;

    &__title {
        @extend .section-title;
    }

    &__cards {
        //this contains the cards for three projects
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        @include tablet {
            margin-top: 20px; //for mobile the cards take care of their own top margin
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &__card {
        //individual project card
        width: 50vw;
        height: 80vw;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        box-shadow: 1px 3px 4px 1px gray;
        transition: all 0.4s ease;

        &:hover, &:active {
            filter: opacity(0.6);
            cursor: pointer;
        }


        @include tablet {
            width: 20vw;
            height: 32vw;
            margin-top: 0;
            margin-bottom: 20px;
            margin-right: 20px;
        }

        @include desktop {
            width: 256px;
            height: 410px;
            margin-right: 50px;
        }

        background-color: white;
    }

    &__card-title {
        //the title box on a project card
        height: 10vw;
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 3vw;
        
        @include tablet {
            height: 4vw;
            font-size: 1.5vw; //prevents spillover for 780px screen size
        }
        @include desktop {
            height: 52px;
            font-size: 20px;
        }
    }

    &__lightspeed {
        //based on the second class, determine background image
        background-image: url("../../Assets/lightspeed/lightspeedPic.png");
        background-size: cover;
        background-position: 60%; //to ensure proper centering
    }

    &__postal-img {
        //this one actually refers to the img element, not the card
        width: 100%;
        margin-bottom: 2vw;

        @include desktop {
            margin-bottom: 26px;
        }
        
    }

    &__hvac {
        //based on the second class, determine background image
        background-image: url("../../Assets/hvac/hvac1.jpg");
        background-size: cover;
        background-position: 60%; //to ensure proper centering
    }

    &__thermometer {
        //based on the second class, determine background image
        background-image: url("../../Assets/Thermostat/thermopic.jpg");
        background-size: cover;
        background-position: 60% 30%; //to ensure proper centering
    }
}